import CloudStorageImage from 'common/assets/images/app/whatwedo/CloudStorage.svg';
import CodeDevImage from 'common/assets/images/app/whatwedo/CodeDev.svg';
import CrossPlatformImage from 'common/assets/images/app/whatwedo/CrossPlatform.svg';
import NetworkSecurityImage from 'common/assets/images/app/whatwedo/NetworkSecurity.svg';
import TestingImage from 'common/assets/images/app/whatwedo/Testing.svg';
import WebInterfaceImage from 'common/assets/images/app/whatwedo/WebInterface.svg';
import { Col, Container, Row } from "common/reusecore/Layout";
import SectionTitle from "common/reusecore/SectionTitle";
import React from "react";
import TrackVisibility from 'react-on-screen';
import styled from 'styled-components';
// import WhatWeDoWrapper from "./style";




const WhatWeDoWrapper = styled.section`
    padding: 90px 0 108px 0;
    overflow: hidden;
    position: relative;
    max-width: 800px;
    margin: auto;



    .description {
        text-align: center;
        width: 50%;
        font-family: 'Merriweather', serif;
        margin: auto;
        color: ${props => props.theme.black};
    }
   
    @media only screen and (max-width: 1280px) {
        padding: 90px 0 210px 0;
        
        
    }
    @media only screen and (max-width: 912px) {
        padding: 50px 0 35px 0;
        
    }
    @media only screen and (max-width: 480px) {
       
    }
    
`




const DoSectionWrapper = styled.div`
  margin-bottom: 80px;
  h3{
    color: #FFCC80;
    font-family: 'Poppins',sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    &>strong {
      font-weight: 800;
    }
  }
  @media only screen and (max-width: 568px) {
    h3, h5, p{
      text-align: center !important;
    }
  }
  h5{
    color: ${props => props.theme.textColor}
    font-weight: 300;
    font-style: italic;
  }

  p{
    margin-top: 20px
  }

  .colImg .img_container {
    width: 100%;
    // height: 300px;
    text-align: center
  }

  .colImg .img_container img {
    width: 200px;
    height: 200px;
    transition: all 0.6s ease-in;
    ${props => props.isVisible ? `
    opacity:1
    `: `
    opacity:0
    `}
  }

  .colContent{
    transition: all 0.6s ease-in;
    ${props => props.isVisible ? `
    opacity:1
    `: `
    opacity:0
    `}
  }




`




const DoSection = (props) => {
  const { title, title2, subTitle, description, image, position = 'left', isVisible } = props;

  // console.log(title, '->', isVisible)

  const textContent = (
    <Col className='colContent' xs={12} sm={8} lg={8}>
      <h3><strong>{title}</strong> {title2}</h3>
      <h5>{subTitle} </h5>

      <p>{description}</p>
    </Col>
  )

  const imageContent = (
    <Col className='colImg' xs={12} sm={4} lg={4}>
      <div className='img_container'>

        <img src={image} alt="img" />
      </div>
    </Col>
  )



  return (
    <DoSectionWrapper isVisible={isVisible}>
      <Row Vcenter>


        {position == 'left' && (<>{textContent}</>)}
        {imageContent}
        {position == 'right' && (<>{textContent}</>)}



      </Row>
    </DoSectionWrapper>
  )
}



const WhatWeDoData = [
  {
    position: 'right',
    title: "UX/UI",
    title2: "Design",
    subTitle: "Flow design, interaction design and user experience",
    description: "We are experienced to help our customers through all stages of design, starting from branding and information architecture to POC, and finishing with UI/UX design.",
    image: WebInterfaceImage
  },
  {
    position: 'right',
    title: "Backend",
    title2: "Development",
    subTitle: "Server side, database and business logic programming",
    description: "We deliver solutions in line with a company's long-term strategy and design customized databases and API's to reflect its business structure.",
    image: CodeDevImage
  },
  {
    position: 'right',
    title: "Frontend",
    title2: "Development",
    subTitle: "Our goal is a perfect user experience",
    description: "We are quick to design MVPs to then create a responsive web application compatible on the full range of devices. We cover mobile first, providing an offline mode though a service worker.",
    image: CrossPlatformImage
  },
  {
    position: 'right',
    title: "Micro",
    title2: "services",
    subTitle: "Productivity, Speed and Flexibility",
    description: "We integrate customers'​ existing tools or use external APIs from a wide range of services. We display our web application data through GraphQL or REST.",
    image: NetworkSecurityImage
  },
  {
    position: 'right',
    title: "Dev-Ops",
    subTitle: "Happier, more engaged teams",
    description: "We equip the customer with Docker while maintaining high availability to avoid costly disruptions. Get the most out of cloud computing platforms like Amazon Web Services or DigitalOcean.",
    image: CloudStorageImage
  },
  {
    position: 'right',
    title: "Testing",
    title2: "drive development",
    subTitle: "TDD approach can easily make changes to their applications",
    description: "Our approch to development is Test Driven . We are comfortable working on complex codebases and we use to test and debugging aur software before start coding.",
    image: TestingImage
  },
]

const WhatWeDo = () => {
  return (
    <WhatWeDoWrapper id="WhatWeDo">
      <Container>
        <SectionTitle withLine sectionPaddingBottom black >
          <h1>What we do</h1>
        </SectionTitle>

        {WhatWeDoData.map((o, idx) => (
          <TrackVisibility partialVisibility key={idx}>
            <DoSection
              {...o}
            />
          </TrackVisibility>
        ))}
      </Container>
    </WhatWeDoWrapper>
  );
};

export default WhatWeDo;
