import React from "react";

import { Container, Row, Col } from "common/reusecore/Layout";
import SectionTitle from "common/reusecore/SectionTitle";
import Button from "common/reusecore/Button";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";

import HelloWrapper, {BrXs} from "./style";

const Hello = () => {
  
  return (
    <HelloWrapper id="hello">
      <Container>
        <SectionTitle UniWidth="65%">
          {/* <h1>Hello!</h1> */}
        </SectionTitle>

        <SectionTitle UniWidth="50%">
          <h1>
            We're a team of  
            <BrXs/>
            <span> full-stack developers </span>
           
  
            
            based in Milan{' '}
            <BrXs/>
            specializing in quality and
  

            <span> high-performance web applications.</span>
          </h1>
        </SectionTitle>

        <p className='description'>
          We are always <b>up to date</b> on new technologies and put them to <br />
        use when servicing our customers to <b>help their businesses grow</b>.<br />
We guide our clients through the integration of new technologies <br />
by providing a wide range of services from software design and <br />
architecture to business <b>process automation</b>.

        </p>

      </Container>
    </HelloWrapper>
  );
};

export default Hello;
