import styled from 'styled-components';

export const BrXs = styled.br`
    display:none
    @media (max-width: 480px) {
        display: block;
    }
`

const HelloWrapper = styled.section`
    // padding: 90px 0 108px 0;
    overflow: hidden;
    position: relative;
   
    h1, h2, h3 {
        color: ${props => props.theme.white};
        font-family: 'Poppins', sans-serif;
        margin: 0px;
        margin-bottom: 40px;
    }

    h1 {
        font-size: 40px
        line-height: 45px;
    }
    h2 {
        font-size: 24px;
        line-height: 35px;
    }

    // h2 span {
    //     background-color: #FFCC80;
    //     color: #455069;
    //     padding: 0px 10px;
    //     margin: 0px 5px;
    // }

    .description {
        text-align: center;
        width: 100%;
        margin: auto;
        color: ${props => props.theme.white};
    }
   
    // @media only screen and (max-width: 1280px) {
    //     padding: 90px 0 210px 0;
        
        
    // }
    // @media only screen and (max-width: 912px) {
    //     padding: 50px 0 35px 0;
        
    // }
    @media only screen and (max-width: 480px) {
        h1{
            font-size: 32px;
        }
    }
    
    
`

export default HelloWrapper;